import React from "react"
import s from "./home_page.module.scss"
import Layout from "../layout"
import ContentContainer from "../../atoms/ContentContainer"
import Services from "../../organisms/Services"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

function HomePage({ pageContext }) {
    return (
        <Layout>
            <section className={s.homePageIntro}>
                <ContentContainer>
                    <h1 className={s.title}>
                        Make your home & business stand out
                    </h1>
                    <hr />
                    <h3 className={s.summary}>
                        Detail oriented painting & plastering craftsmen
                        delivering world class finishes to home & business
                        renovations across Birmingham & West Midlands.
                    </h3>
                    <p>
                        We offer a variety of spray painting & plaster services
                        ranging from skimming individual rooms to
                        plasterboarding, plastering & decorating full homes or
                        businesses. Using our industry leading spraying machine
                        & ready mix plaster, we can spray plaster to achieve a
                        finish which requires minimal preparation prior to
                        painting.
                    </p>
                    <p>
                        Between our airless & HVLP spraying machines, we can
                        paint entire rooms, individual units, garden furniture
                        faster & more economical than our competitors; checkout
                        our Portfolio for examples of our work.
                    </p>
                </ContentContainer>
            </section>
            <section className={s.portfolioSection}>
                <ContentContainer>
                    <h3 className={s.title}>Portfolio</h3>
                    <Services services={pageContext.services} />
                </ContentContainer>
            </section>
            <section>
                <ContentContainer>
                    <h3 className={s.title}>Services we offer</h3>
                    <ul className={s.services}>
                        {pageContext.skills.map(x => (
                            <li className={s.service}>
                                <span className={s.icon}>
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        size={"2x"}
                                    />
                                </span>
                                {x}
                            </li>
                        ))}
                    </ul>
                </ContentContainer>
            </section>
        </Layout>
    )
}

export default HomePage
