import React from "react"
import s from "./Services.module.scss"

function Services({ services }) {
    return (
        <div className={s.services}>
            {services.map(service => (
                <a
                    key={service.slug}
                    className={`${s[service.className]} ${s.service}`}
                    href={`/services/${service.slug}`}
                >
                    <h2 className={s.title}>{service.title}</h2>
                </a>
            ))}
        </div>
    )
}

export default Services
